// eslint-disable-next-line import/no-cycle
import apiRequest from 'Utils/apiRequest'
import noAuthApiRequest from 'Utils/noAuthApiRequest'

export default {
  getEmbedFromUrl(url) {
    return apiRequest.get(`/essentials/iframelyembed/?url=${url}`)
  },
  getSchoolAppDataConstants() {
    return noAuthApiRequest.get('/essentials/schoolappdata/')
  },
  finishPayment(razorpayPaymentData) {
    return apiRequest.post('/essentials/finish-payment/', {
      paymentData: razorpayPaymentData,
    })
  },
}
