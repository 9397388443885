import globalsService from 'Services/globalsService'
import loadScript from 'Utils/loadScript'
import progressService from 'Services/progressService'
import logger from 'Utils/logger'
// eslint-disable-next-line import/no-cycle
import essentialsService from 'Services/essentialsService'

let initCalled = false

async function isSetup(hideChatButton = true) {
  if (typeof window === 'undefined') return false
  if (!window.fcWidget) return false
  if (initCalled) return true
  let appData = null
  try {
    appData = await essentialsService.getSchoolAppDataConstants()
    appData = appData.result
  } catch (err) {
    logger.log({ freshchat_error: err })
    return false
  }
  const token = appData.freshchatToken
  const initConfig = {
    config: {
      headerProperty: {
        hideChatButton,
      },
    },
    token,
    host: 'https://wchat.freshchat.com',
  }
  const user = globalsService.getLoggedUser()
  if (user) {
    initConfig.externalId = user.email
    initConfig.firstName = user.name
    initConfig.email = user.email
    initConfig.phone = user.mobileNumber
  }
  window.fcWidget.init(initConfig)
  window.fcWidget.on('widget:opened', () => {
    progressService.done()
  })
  initCalled = true
  return true
}

function reset() {
  // prepare for reinit
  initCalled = false
  if (typeof window === 'undefined') return
  if (window.fcWidget) {
    window.fcWidget.destroy()
  }
}

const load = (callback) =>
  loadScript(
    'https://wchat.freshchat.com/js/widget.js',
    'freshchatScript',
    callback
  )

export default {
  async show() {
    load(async () => {
      if (!(await isSetup())) return
      progressService.load()
      window.fcWidget.open()
    })
  },
  logout() {
    load(async () => {
      if (!(await isSetup())) return
      window.fcWidget.user.clear()
      window.fcWidget.destroy()
    })
  },
  loadWithButton() {
    // load on landing page, shows chat button
    // first reset
    reset()
    load(async () => {
      isSetup(false)
    })
  },
  reset,
}
