export default function GetSchoolUrl(s, path = '', exact = false) {
  // use exact on places not visible to user (eg meta headers)
  // exact is enabled only on prod
  // if relativePath not provided use '' default so that no trailing back slash
  // DON'T SPREAD ENV VARIABLES DOESN'T WORK
  // eslint-disable-next-line prefer-destructuring
  const DEBUG = process.env.DEBUG
  if (!s) {
    if (DEBUG) return `${exact ? 'http://' : ''}localhost:3000${path}`
    return `${exact ? 'https://' : ''}ylurn.com${path}`
  }
  const subdomain = s.slug
  const { customDomain } = s
  if (customDomain) return `${exact ? 'https://' : ''}${s.customDomain}${path}`
  if (DEBUG) {
    return `${exact ? 'http://' : ''}${subdomain}.localhost:3000${path}`
  }
  return `${exact ? 'https://' : ''}${subdomain}.ylurn.com${path}`
}
