import globalsService from 'Services/globalsService'
// eslint-disable-next-line import/no-cycle
import SupportChatService from 'Services/SupportChatService'
import sentryService from 'Services/sentryService'
import axios from 'axios'
import mixpanelService from 'Services/mixpanelService'
import browserStorageService from 'Services/browserStorageService'
import noAuthApiRequest from 'Utils/noAuthApiRequest'
import GetSchoolUrl from 'Utils/GetSchoolUrl'

export default {
  signup(d, redirectUrl) {
    const u = d.user
    mixpanelService.signup(u)
    this.login(d, redirectUrl)
  },
  saveLoginData(d) {
    const u = d.user
    globalsService.setCookie('authToken', d.authToken)
    globalsService.setCookie('loggedUser', u)
  },
  login(d, redirectUrl) {
    const u = d.user
    this.saveLoginData(d)
    sentryService.login(u)
    mixpanelService.login(u)
    if (redirectUrl) {
      window.location.href = redirectUrl
    } else {
      window.location.href = '/courses'
    }
  },
  logout(reload = true) {
    if (globalsService.getCookie('authToken')) {
      noAuthApiRequest
        .post('/logout/', { authToken: globalsService.getCookie('authToken') })
        .then(
          () => {},
          () => {}
        )
    }
    globalsService.removeCookie('authToken')
    globalsService.removeCookie('loggedUser')
    browserStorageService.logout()
    SupportChatService.logout()
    sentryService.logout()
    mixpanelService.logout()
    if (reload && typeof window !== 'undefined') {
      window.location.href = '/courses'
    }
  },
  getAuthResponse(authToken) {
    // add school headers to this because
    // school roles sent based on this
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `key=${authToken}`,
    }
    const school = globalsService.getConstant('school')
    if (school) {
      headers['YLURN-SCHOOL-ID'] = school.id
    }
    const customAxios = axios.create({
      baseURL: `${process.env.apiServerOrigin}/api/v1`,
      headers,
    })
    return customAxios.get('/login/auth/response/')
  },
  mobileNumberAuthStart(data) {
    return noAuthApiRequest.post('/auth/start/mobilenumber/', data)
  },
  signup2(user) {
    return noAuthApiRequest.post('/auth/signup/', user)
  },
  authenticate2(data) {
    return noAuthApiRequest.post('/auth/login/', data)
  },
  verifyCode(data) {
    return noAuthApiRequest.post('/login/code/verify/', data)
  },
  goToSchool: (s, authToken = null) => {
    // auth token passed explicitly from institute signup
    let token = authToken
    if (!token) token = globalsService.getCookie('authToken')
    window.location.href = GetSchoolUrl(s, `/auth/login/${token}/`, true)
  },
  resendOTP(data) {
    return noAuthApiRequest.post('/auth/resend/otp/', data)
  },
}
