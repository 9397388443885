import axios from 'axios'
import globalsService from 'Services/globalsService'

const noAuthApiAxios = axios.create({
  baseURL: `${process.env.apiServerOrigin}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
})

noAuthApiAxios.interceptors.request.use(
  (config) => {
    const school = globalsService.getConstant('school')
    if (school) {
      // eslint-disable-next-line no-param-reassign
      if (school.id) config.headers['YLURN-SCHOOL-ID'] = school.id
      // eslint-disable-next-line no-param-reassign
      if (school.slug) config.headers['YLURN-SCHOOL-SLUG'] = school.slug
      // eslint-disable-next-line no-param-reassign
      if (school.customDomain)
        // eslint-disable-next-line no-param-reassign
        config.headers['YLURN-SCHOOL-DOMAIN'] = school.customDomain
    }
    return config
  },
  (error) => Promise.reject(error)
)

noAuthApiAxios.interceptors.response.use(
  (response) => response.data,
  (error) => Promise.reject(error.response)
)

export default noAuthApiAxios
