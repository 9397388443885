import axios from 'axios'
import globalsService from 'Services/globalsService'
import progressService from 'Services/progressService'
import ErrorString from 'Utils/ErrorString'
// eslint-disable-next-line import/no-cycle
import authService from 'Services/authService'

const LOGIN_REQUIRED_MESSAGE = 'Login required'

const apiAxios = axios.create({
  baseURL: `${process.env.apiServerOrigin}/api/v1`,
  headers: {
    'Content-Type': 'application/json',
  },
})

function showProgressBar(config) {
  let enabled = false
  // set hideProgress=true to disable for get requests
  // set showProgress=true to enable for any requests
  if (
    (typeof window !== 'undefined' &&
      config.method === 'get' &&
      !config.hideProgress) ||
    config.showProgress
  )
    enabled = true
  return enabled
}

apiAxios.interceptors.request.use(
  (config) => {
    if (showProgressBar(config)) progressService.load()
    const auth = globalsService.getCookie('authToken')
    const school = globalsService.getConstant('school')
    // eslint-disable-next-line no-param-reassign
    if (auth) config.headers.Authorization = `key=${auth}`
    if (school) {
      // eslint-disable-next-line no-param-reassign
      if (school.id) config.headers['YLURN-SCHOOL-ID'] = school.id
      // eslint-disable-next-line no-param-reassign
      if (school.slug) config.headers['YLURN-SCHOOL-SLUG'] = school.slug
      // eslint-disable-next-line no-param-reassign
      if (school.customDomain)
        // eslint-disable-next-line no-param-reassign
        config.headers['YLURN-SCHOOL-DOMAIN'] = school.customDomain
    }
    return config
  },
  (error) => Promise.reject(error)
)

apiAxios.interceptors.response.use(
  (response) => {
    if (showProgressBar(response.config)) progressService.done()
    return response.data // returns just the response body (axios response is more detailed)
  },
  (error) => {
    // handle cancelled request nprogress
    // call for all requests since load < 0 is set to 0
    progressService.done()
    const rejection = error.response
    // note in axios, rejection will be undefined when server is not reachable
    // in angularjs its response object with status code -1
    if (!rejection) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({ status: -1 })
    }
    const statusCode = rejection.status
    const errorMessage = ErrorString(rejection)
    if (showProgressBar(rejection.config)) progressService.done()
    if (statusCode === 401 && errorMessage === LOGIN_REQUIRED_MESSAGE) {
      authService.logout(false) // no reload logout
    }
    // TODO: check for "Incorrect API endpoint" and give some other alert
    return Promise.reject(rejection)
  }
)

export default apiAxios
