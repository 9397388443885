export default function loadScript(url, scriptName, callback) {
  if (typeof document === 'undefined') {
    // eslint-disable-next-line no-console
    console.log('loadScript called on server')
    return
  }
  const existingScript = document.getElementById(scriptName)
  if (!existingScript) {
    const script = document.createElement('script')
    script.src = url
    script.id = scriptName
    document.body.appendChild(script)

    script.onload = () => {
      // set attribute on script when loaded
      script.setAttribute('ylurn-loaded', 'true')
      if (callback) callback()
    }
  } else {
    const scriptLoaded = existingScript.getAttribute('ylurn-loaded')
    if (scriptLoaded && callback) callback()
    if (!scriptLoaded) {
      existingScript.onload = () => {
        if (callback) callback()
      }
    }
  }
}
