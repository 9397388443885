import store from 'store'

const isBrowser = typeof window !== 'undefined'

export default {
  get: (key) => {
    if (isBrowser) return store.get(key)
    return null
  },
  set: (key, value) => {
    if (isBrowser) store.set(key, value)
  },
  logout: () => {
    if (!isBrowser) return
    // list of keys that shouldn't be cleared on logout
    const publicKeys = [
      'courses.public.popular',
      'courses.school.paid',
      'courses.school.public',
    ]
    store.each((value, key) => {
      // dont clear public cache
      if (publicKeys.indexOf(key) === -1) {
        store.remove(key)
      }
    })
  },
}
